import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {colors, font, fontfamily} from '../styles/variables'

import Header from './header'
import Footer from './footer'
import MobileNav from './mobile-nav'

const GlobalStyle = createGlobalStyle`
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  /* ul[class],
  ol[class], */
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    line-height: 1.5;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
    inner-shadow: none;
  }

  div[class^="portableText__"] {
    max-width: 680px;
    margin: 0 auto;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  html {
    font-size: 62.5%;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: ${colors.black};
    font-family: ${fontfamily.ja};
    @media (min-width: 1024px) {
      background: #fafafa;
    }
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 3.2rem;
    line-height: 1.75;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${colors.blue};
    :hover{
      opacity: 0.7;
    }
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 60px;
  max-width: 1024px;
  background: #fff;
  border-radius: 12px;
  @media (min-width: 768px) {
    margin-top: 120px;
  }
  @media (min-width: 1024px) {
    margin-top: 160px;
    padding: 0 0 8rem 0;
    box-shadow: 0 4px 64px 0 rgba(0, 0, 0, 0.05);
  }
`

const Wrap = styled.div`
  &.navActive{
    height: 100vh;
    overflow-y: hidden;
  }
`

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, siteSubTitle}) => (
  <Wrap className={(showNav && 'navActive')}>
    <GlobalStyle />
    <Header
      siteTitle={siteTitle}
      subTitle={siteSubTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
    />
    <MobileNav
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
    />
    <Wrapper>{children}</Wrapper>
    <Footer />
  </Wrap>
)

export default Layout
