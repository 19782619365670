import React from 'react'
import {format, isFuture} from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({slug}) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture ({publishedAt}) {
  return !isFuture(publishedAt)
}

export function getBlogUrl (publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getNewsUrl (publishedAt, slug) {
  return `/news/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getProficiencyTestsUrl (publishedAt, slug) {
  return `/proficiency-tests/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getFundraisingsUrl (publishedAt, slug) {
  return `/fundraisings/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function getSummerWinterSchoolsUrl (slug) {
  return `/summer-winter-schools/${slug.current || slug}/`
}

export function getAdmissionsUrl (slug) {
  return `/admissions/${slug.current || slug}/`
}

export function getFaqUrl (slug) {
  return `/faq/${slug.current || slug}/`
}

export function getPageUrl (slug) {
  return `/${slug.current || slug}/`
}

export function buildImageObj (source = {asset: {}}) {
  const imageObj = {
    asset: {_ref: source.asset._ref || source.asset._id}
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText (blocks) {
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

export const DisplayFormikState = props =>
  <div style={{margin: '1rem 0'}}>
    <h3 style={{fontFamily: 'monospace'}} />
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem'
      }}
    >
      <strong>props</strong> ={' '}
      {JSON.stringify(props, null, 2)}
    </pre>
  </div>
