import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'

import {getNewsUrl} from '../lib/helpers'
import styled from 'styled-components'
import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.div`
  width: 100%;
  height: 22px;
`

const StyledLink = styled(props => <Link {...props} />)`
  background: ${colors.red};
  height: 22px;
  font-family: ${fontfamily.ja};
  color: white;
  font-size: 14px;
  text-align: center;
  display: block;
  text-decoration: none;
  font-weight: 500;
  transition: all 250ms;
  :hover {
    opacity: 1;
    background: #FFE7E7;
    color: ${colors.red};
}
`

const query = graphql`
  query HeaderNewsQuery {
    headerNews: allSanityNews(
      limit: 1
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } , showOnHeader: { eq: true} }
    ) {
      edges {
        node {
          _id
          showOnHeader
          publishedAt
          title
          slug{
            current
          }
        }
      }
    }
  }
`
const HeaderBanner = ({data}) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.headerNews) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        } else {
          return (
            <>
              {data && data.headerNews && data.headerNews.edges.length > 0 &&
              <Wrapper>
                {data.headerNews.edges.map(({node, index}) => {
                  return (
                    <StyledLink to={getNewsUrl(node.publishedAt, node.slug.current)} key={node._id}>
                      {node.title}
                    </StyledLink>
                  )
                })}
              </Wrapper>
              }
            </>
          )
        }
      }}
    />
  )
}

export default HeaderBanner
