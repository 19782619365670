import React from 'react'
import {Link} from 'gatsby'

import Figure from './Figure'
import FileDownload from './FileDownload'
// import Small from './Small'

import styled from 'styled-components'

import {getNewsUrl, getProficiencyTestsUrl, getFundraisingsUrl, getSummerWinterSchoolsUrl, getAdmissionsUrl, getFaqUrl, getPageUrl} from '../lib/helpers'

const Small = styled.small`
  font-size: 1.2rem;
`

const serializers = {
  marks: {
    small: Small,
    internalLink: ({mark, children}) => {
      let href
      const {slug = {}, _type, publishedAt} = mark.reference

      if (_type === 'news') {
        href = getNewsUrl(publishedAt, slug.current)
      } else if (_type === 'proficiencyTests') {
        href = getProficiencyTestsUrl(publishedAt, slug.current)
      } else if (_type === 'fundraisings') {
        href = getFundraisingsUrl(publishedAt, slug.current)
      } else if (_type === 'summerWinterSchools') {
        href = getSummerWinterSchoolsUrl(slug.current)
      } else if (_type === 'admissions') {
        href = getAdmissionsUrl(slug.current)
      } else if (_type === 'faq') {
        href = getFaqUrl(slug.current)
      } else {
        href = getPageUrl(slug.current)
      }
      return <Link to={href}>{children}</Link>
    },
    link: ({mark, children}) => {
      const {blank, href} = mark
      return blank
        ? <a href={href} target='_blank' rel='noopener noreferrer'>{children}</a>
        : <a href={href}>{children}</a>
    }
  },
  types: {
    mainImage: Figure,
    fileDownload: FileDownload
  }
}

export default serializers
