import React from 'react'
import {Link} from 'gatsby'
import Icon from './icon'

import {cn} from '../lib/helpers'
import styled from 'styled-components'
import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.nav`
  display: block;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  position: fixed;
  background: ${colors.navy};
  z-index: 10;
  pointer-events: none;
  transform: translateY(-100%);
  transition: transform 0.4s ease-out, opacity 0.1s ease-in-out 0.2s;
  /* opacity: 0.6; */
  padding: 8rem 1.6rem 24rem 1.2rem;
  &.active{
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
`

const Inner = styled.div`
  margin: 4rem 0 0 0;
  display: block;
  overflow-y: auto;
  @media (min-width: 768px) {
    margin: 8rem 0 0 0;
 }
`

const List = styled.ul`
  display: block;
  font-family: ${fontfamily.jaRounded};
  font-size: ${props => !props.small ? '1.6rem' : '1.4rem'};
  font-weight: ${props => !props.small ? '700' : '400'};
  margin-bottom:  ${props => !props.small ? '4.0rem' : 'none'};
`

const Item = styled.li`
  margin: 0 0 2.0rem 0;
`

const StyledLink = styled(props => <Link {...props} />)`
  color: white;
  text-decoration: none;
`
const MobileNav = ({onHideNav, onShowNav, showNav}) => (
  <Wrapper className={(showNav && 'active')}>
    <Inner>
      <List>
        <Item><StyledLink to='/news/'>ニュース</StyledLink></Item>
        <Item><StyledLink to='/admissions/'>入園案内</StyledLink></Item>
        <Item><StyledLink to='/about/'>ニューヨーク育英学園について</StyledLink></Item>
        <Item><StyledLink to='/access/'>アクセス</StyledLink></Item>
        <Item><StyledLink to='/contact/'>お問い合わせ</StyledLink></Item>
      </List>
      <List small>
        <Item><StyledLink to='/workshops/'>語学講座・大人向け講座など</StyledLink></Item>
        <Item><StyledLink to='/summer-winter-schools/'>サマー・ウィンター スクール</StyledLink></Item>
        <Item><StyledLink to='/proficiency-tests/'>検定試験</StyledLink></Item>
        <Item><StyledLink to='/school-bus/'>スクールバス</StyledLink></Item>
        <Item><StyledLink to='/home-school/'>ホームスクール</StyledLink></Item>
        <Item><StyledLink to='/fundraisings/'>ファンドレイジング</StyledLink></Item>
        <Item><StyledLink to='/faq/'>FAQ</StyledLink></Item>
        <Item><StyledLink to='/calendar/'>年間予定カレンダー</StyledLink></Item>
        <Item><StyledLink to='/downloads/'>申請書・学習ツールダウンロード</StyledLink></Item>
        <Item><StyledLink to='/careers/'>採用情報</StyledLink></Item>
        <Item><StyledLink to='/terms/'>利用規約</StyledLink></Item>
      </List>
    </Inner>
  </Wrapper>
)

export default MobileNav
